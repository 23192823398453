<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USER_INVITATIONS.EDIT.PAGE_TITLE">
    <BaseHasPermission :permissionNames="['invitation.update']">
      <v-card class="pa-0" flat color="transparent" v-if="!isLoading">
        <v-card-text class="pa-0">
          <v-row>
            <v-col cols="12">
              <BaseBackButton />
            </v-col>
          </v-row>

          <UserInvitationInputForm
            v-model="userInvitation"
            :seminar-participant="seminarParticipant"
            :backend-validation-errors="validationErrors"
          />
        </v-card-text>

        <v-card-text class="py-0 px-3">
          <AssignRolesTable v-model="userInvitation.roles" class="pt-3" />
        </v-card-text>

        <v-card-actions class="pt-6 px-3">
          <v-spacer />

          <BaseButtonTertiary @click="clear">
            {{ $t("USER_MANAGEMENT.USER_INVITATIONS.EDIT.ACTIONS.CLEAR") }}
          </BaseButtonTertiary>

          <BaseButton @click="update" color="primary" small>
            {{ $t("USER_MANAGEMENT.USER_INVITATIONS.EDIT.ACTIONS.UPDATE") }}
          </BaseButton>
        </v-card-actions>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import UserInvitationInputForm from "@components/UserInvitation/UserInvitationInputForm";
import AssignRolesTable from "@components/AssignTables/Roles/AssignRolesTable";
import { mapActions } from "vuex";
import formValidation from "@mixins/formValidation";

export default {
  name: "UserInvitationEdit",

  components: {
    AssignRolesTable,
    UserInvitationInputForm,
    ModuleSubPage,
  },

  mixins: [formValidation],

  data() {
    return {
      isLoading: false,
      userInvitation: {
        id: null,
        emailMain: null,
        personId: null,
        roles: [],
      },
      seminarParticipant: {
        id: null,
        seminarCompanyId: null,
        userId: null,
        countryId: null,
        navisionNumber: null,
        salutation: null,
        firstName: null,
        lastName: null,
        street: null,
        zipCode: null,
        city: null,
        email: null,
        phone: null,
        mobile: null,
        isIGBCEMember: null,
        isActive: null,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      },
    };
  },

  computed: {
    userInvitationId() {
      return this.$route.params.id;
    },
  },

  methods: {
    ...mapActions("notifications", ["notify"]),

    async getUserInvitation() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.showUserInvitation(this.userInvitationId);

        if (data.data.person) {
          this.seminarParticipant = data.data.person;
        }

        this.userInvitation = {
          id: data.data.id,
          emailMain: data.data.email,
          personId: data.data.personId,
          roles: data.data.roles.map(({ id }) => id),
        };
      } catch (err) {
        console.error(err);
        this.notify({
          type: "error",
          message: "USER_MANAGEMENT.USER_INVITATIONS.EDIT.MESSAGES.LOADING_ERROR",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async changeRoute(data) {
      try {
        await this.$router.push({
          name: "management-user-invitation-show",
          params: { id: data.data.id },
        });
      } catch {
        return;
      }
    },

    async update() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.userManagement.updateUserInvitation({
          invitationId: this.userInvitation.id,
          email: this.userInvitation.emailMain,
          personId: this.userInvitation.personId,
          roleIds: this.userInvitation.roles.map(({ id }) => id),
        });

        this.notify({
          type: "success",
          message: "USER_MANAGEMENT.USER_INVITATIONS.EDIT.MESSAGES.SUCCESS",
        });

        this.changeRoute(data);
      } catch (err) {
        console.error(err);
        this.setValidationErrors(err, false);
      } finally {
        this.isLoading = false;
      }
    },

    clear() {
      this.getUserInvitation();
    },
  },

  created() {
    this.getUserInvitation();
  },
};
</script>
